const arrangeDatacapture = {

    getData: function(e) {
        // console.log("Event", e);
        // console.log("target", e.target);

        let splits = (window.location + "").split("#");
        if (splits.length > 1) {
            window.location = splits[0] + "#arrange-demo-popup";
        } else {
            window.location = window.location + "#arrange-demo-popup";
        }

    },
    
    getFormDataString: function getFormDataString(formEl) {
        const formData = new FormData(formEl),
            data = [];

        for (let item of formEl.elements) {
            switch (item.name) {
                case "privacy":
                    if (item.checked) {
                        formData.set("privacy", "Privacy Policy Accepted");
                    }
                    break;
                case "_gotcha":
                case "inbox_key":
                    if (item.value !== "") {
                        formData.set(item.name, item.value);
                    }
                    break;
                default:

            }
        }

        for (let keyValuePair of formData) {
            // console.log("key value", keyValuePair[0], keyValuePair[1]);
            data.push(encodeURIComponent(keyValuePair[0]) + "=" + encodeURIComponent(keyValuePair[1]));
        }

        // for (let value of formData.values()) {
        //     console.log("values", value);
        // }

        return data.join("&");
    },

    submitButtonText: function()  {
        // submit element value is not respected without this.
        const submitEl = document.getElementById("arrange-demo-submit");
        submitEl.value = "Submit";
    },

    addClickListeners: function() {
        // for arrange demo links
        const els = document.getElementsByClassName("arrange-demo");
        for (let el of els) {
            el.addEventListener("click", arrangeDatacapture.getData);
        }
    },

    addListenersToForm: function() {
        // Override the submit event
        const formEl = document.getElementById("arrange-demo-form");
        formEl.addEventListener("submit", function (e) {

            e.preventDefault();

            // if (grecaptcha) {
            //     const recaptchaResponse = grecaptcha.getResponse();
            //     if (!recaptchaResponse) { // reCAPTCHA not clicked yet
            //         return false;
            //     }
            // }

            const request = new XMLHttpRequest();

            request.addEventListener("load", function () {

                // hide form
                const formEl = document.getElementById("arrange-demo-form");
                formEl.classList.add("hidden");

                // show success
                const successEl = document.getElementById("arrange-demo-success");
                successEl.classList.remove("hidden");

                if (request.status === 303) { // CloudCannon redirects on success
                    // It worked
                    // NO IT DOESN't
                }
            });

            const formEl = document.getElementById("arrange-demo-form");

            request.open(formEl.method, formEl.action);
            request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            request.send(arrangeDatacapture.getFormDataString(formEl));

        });
    },

    resetModal: function() {
        // hide form
        const formEl = document.getElementById("arrange-demo-form");
        formEl.classList.remove("hidden");

        // show success
        const successEl = document.getElementById("arrange-demo-success");
        successEl.classList.add("hidden");
    }
    
};

arrangeDatacapture.addClickListeners();
arrangeDatacapture.addListenersToForm();